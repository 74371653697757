import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider"; // Use AuthProvider for authentication state

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  // Redirect to login if the user is not authenticated
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
